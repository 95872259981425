const FETCH_ENTERPRISE_GROUPS_REQUEST = 'FETCH_ENTERPRISE_GROUPS_REQUEST';
const FETCH_ENTERPRISE_GROUPS_SUCCESS = 'FETCH_ENTERPRISE_GROUPS_SUCCESS';
const FETCH_ENTERPRISE_GROUPS_FAILURE = 'FETCH_ENTERPRISE_GROUPS_FAILURE';
const CLEAR_ENTERPRISE_GROUPS = 'CLEAR_ENTERPRISE_GROUPS';

export {
  FETCH_ENTERPRISE_GROUPS_REQUEST,
  FETCH_ENTERPRISE_GROUPS_SUCCESS,
  FETCH_ENTERPRISE_GROUPS_FAILURE,
  CLEAR_ENTERPRISE_GROUPS,
};
